<template>
  <div class="wrap">
    <p class="title">角色权限管理</p>
    <!-- 搜索 -->
    <a-form layout="inline" :model="formState">
      <a-form-item label="角色名称">
        <a-input v-model:value.trim="formState.roleName" placeholder="请输入角色名称" size="small" allowClear></a-input>
      </a-form-item>
      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-button size="small" @click="reset">重置</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" size="small" @click="showAddEditModal">新增角色</a-button>
      </a-form-item>
    </a-form>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <!-- 新增 / 编辑 角色弹窗 -->
  <add-edit-role-modal ref="AddEditRoleRef" @addOrUpdateSuccess="search"></add-edit-role-modal>
</template>

<script>
import { defineComponent, createVNode, ref, toRefs, reactive, onMounted } from 'vue';
import { Modal, message } from 'ant-design-vue';
import api from '@/services';
import AddEditRoleModal from './AddEditRoleModal';
const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true
  },
  {
    title: '角色名称',
    key: 'roleName',
    dataIndex: 'roleName',
    align: 'center',
    ellipsis: true
  },
  {
    title: '创建时间',
    key: 'createTime',
    dataIndex: 'createTime',
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 200,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditRoleModal
  },
  setup() {
    onMounted(() => {
      getRoleList();
    });

    const AddEditRoleRef = ref();

    const addEditVisible = ref(false); // 控制 新增 & 编辑 用户弹窗显隐

    const isEdit = ref(false);

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      roleName: ''
    });

    // 获取用户列表 table data
    const getRoleList = async () => {
      const params = {
        ...formState,
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };
      const { data, success, msg } = await api.getRoleList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      } else {
        message.error(msg);
      }
    };

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getRoleList();
    };

    // 显示新增/编辑角色弹窗
    const showAddEditModal = e => {
      let rowData;
      if (e.record) {
        isEdit.value = true;
        rowData = e.record;
      } else {
        isEdit.value = false;
      }
      AddEditRoleRef.value.showModal(isEdit.value, rowData);
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除角色 ${row.record.roleName} 吗？`
        ),

        async onOk() {
          let { success, msg } = await api.delRoleById(row.record.id);
          if (success) {
            message.success('删除成功！');
            getRoleList();
          } else {
            message.error(msg);
          }
        }
      });
    };

    const search = () => {
      tableState.curPage = 1;
      getRoleList();
    };

    const reset = () => {
      tableState.curPage = 1;
      Object.keys(formState).map(key => (formState[key] = ''));
      getRoleList();
    };

    return {
      AddEditRoleRef,
      addEditVisible,
      formState,
      getRoleList,
      handlePageChange,
      showAddEditModal,
      search,
      reset,
      del,
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .status-tag {
        background-color: #4acc90;
        color: #fff;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
          margin-right: 14px;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
